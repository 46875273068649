import {
  UPDATE_FORM_VALUE,
  SET_IS_SHIPPING_SAME_AS_BILLING,
  CHECKOUT_FORMS_ERRORS,
  CHECKOUT_RESET_FORMS_ERRORS,
  RESET_NAV_STATE,
  LOGOUT,
  UPDATE_CHECKOUT_TRANSACTION_STATUS,
  RESET_CHECKOUT_TRANSACTION_STATUS,
  UPDATE_TRANSACTION_STATUS,
  RESET_TRANSACTION_STATUS,
} from '../actions/actionTypes';
import { FRANCE } from '../constants/country';

const initialState = {
  profile: {
    gender: '',
    firstname: '',
    lastname: '',
    phone_number: '',
    birthdate: [],
  },
  profileErrors: {},
  shipping: {
    company_name: '',
    first_name: '',
    last_name: '',
    postcode: '',
    line_1: '',
    line_2: '',
    city: '',
    country: FRANCE,
    county: '',
    phone: '',
  },
  shippingErrors: {},
  billing: {
    company_name: '',
    first_name: '',
    last_name: '',
    postcode: '',
    line_1: '',
    line_2: '',
    city: '',
    county: '',
    country: FRANCE,
    phone: '',
  },
  billingErrors: {},
  isShippingSameAsBilling: true,
  // FIXME: Put this into another reducer
  checkoutTransactionStatus: {
    is_3dsecure: null,
    post_3dsecure_uri: null,
    pre_3dsecure_uri: null,
    status: null,
    uuid: null,
  },
  transactionStatus: '',
  errors: true,
};

const checkoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_SHIPPING_SAME_AS_BILLING:
      return {
        ...state,
        isShippingSameAsBilling: action.payload,
      };
    case UPDATE_FORM_VALUE:
      return {
        ...state,
        [action.form]: {
          ...state[action.form],
          [action.field]: action.value,
        },
      };
    case CHECKOUT_FORMS_ERRORS:
      return {
        ...state,
        ...action.payload,
      };
    case CHECKOUT_RESET_FORMS_ERRORS:
      return {
        ...state,
        profileErrors: {},
        shippingErrors: {},
        billingErrors: {},
      };
    case RESET_NAV_STATE:
      return {
        ...state,
        errors: true,
        shippingErrors: {},
        billingErrors: {},
      };
    case UPDATE_CHECKOUT_TRANSACTION_STATUS:
      return {
        ...state,
        checkoutTransactionStatus: action.payload,
      };
    case RESET_CHECKOUT_TRANSACTION_STATUS:
      return {
        ...state,
        checkoutTransactionStatus: {
          ...initialState.checkoutTransactionStatus,
        },
      };
    case UPDATE_TRANSACTION_STATUS:
      return {
        ...state,
        transactionStatus: action.value,
      };
    case RESET_TRANSACTION_STATUS:
      return {
        ...state,
        transactionStatus: initialState.transactionStatus,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default checkoutReducer;

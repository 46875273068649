// Checkout Success constants
export const PENDING = 'PENDING';
export const IN_TRANSIT = 'IN_TRANSIT';
export const CHARGEABLE = 'CHARGEABLE';
export const FAILED = 'FAILED';
export const CANCELED = 'CANCELED';
export const PAID = 'PAID';
export const SUCCEEDED = 'SUCCEEDED';
export const TVA = 0.2;

export const CHECKOUT_SUCCEEDED = [PAID, SUCCEEDED];
export const CHECKOUT_FAILED = [FAILED, CANCELED];
export const CHECKOUT_PENDING = [PENDING, IN_TRANSIT, CHARGEABLE];
export const CHECKOUT_ENUM_RESPONSE = [
  PENDING,
  IN_TRANSIT,
  CHARGEABLE,
  FAILED,
  CANCELED,
  PAID,
  SUCCEEDED,
  '',
];

// App
export const OPEN_MENU = 'OPEN_MENU';
export const CLOSE_MENU = 'CLOSE_MENU';
export const OPEN_BASKET = 'OPEN_BASKET';
export const CLOSE_BASKET = 'CLOSE_BASKET';
export const SELECT_MONIMALZ = 'SELECT_MONIMALZ';
export const START_SCROLLING = 'START_SCROLLING';
export const STOP_SCROLLING = 'STOP_SCROLLING';
export const RESET_NAV_STATE = 'RESET_NAV_STATE';
export const SHOW_ERROR = 'SHOW_ERROR';
export const HIDE_ERROR = 'HIDE_ERROR';
export const SHOW_TOAST = 'SHOW_TOAST';
export const HIDE_TOAST = 'HIDE_TOAST';
export const TOKEN_OK = 'TOKEN_OK';
export const TOKEN_RESET = 'TOKEN_RESET';
export const TOKEN_FAILED = 'TOKEN_FAILED';
export const WELCOME_ANIMATION_END = 'WELCOME_ANIMATION_END';
export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING';
export const SET_FROM_WEBSITE = 'SET_FROM_WEBSITE';

// CHECKOUT
export const UPDATE_FORM_VALUE = 'UPDATE_FORM_VALUE';
export const SET_IS_SHIPPING_SAME_AS_BILLING =
  'SET_IS_SHIPPING_SAME_AS_BILLING';
export const CHECKOUT_FORMS_ERRORS = 'CHECKOUT_FORMS_ERRORS';
export const CHECKOUT_RESET_FORMS_ERRORS = 'CHECKOUT_RESET_FORMS_ERRORS';
export const UPDATE_CHECKOUT_TRANSACTION_STATUS =
  'UPDATE_CHECKOUT_TRANSACTION_STATUS';
export const RESET_CHECKOUT_TRANSACTION_STATUS =
  'RESET_CHECKOUT_TRANSACTION_STATUS';
export const ACCOUNT_FORMS_ERRORS = 'ACCOUNT_FORMS_ERRORS';
export const FORMS_ERRORS = 'FORMS_ERRORS';
export const REGISTER_FORMS_ERRORS = 'REGISTER_FORMS_ERRORS';

export const SUBMIT_ADDRESSES_FORM = 'SUBMIT_ADDRESSES_FORM';
export const SUBMIT_ADDRESSES_FORM_SUCCESS = 'SUBMIT_ADDRESSES_FORM_SUCCESS';
export const SUBMIT_ADDRESSES_FORM_FAILURE = 'SUBMIT_ADDRESSES_FORM_FAILURE';
export const UPDATE_TRANSACTION_STATUS = 'UPDATE_TRANSACTION_STATUS';
export const RESET_TRANSACTION_STATUS = 'RESET_TRANSACTION_STATUS';

// CHECKOUT
export const REQUEST_ADD_TO_CART = 'REQUEST_ADD_TO_CART';
export const REQUEST_ADD_TO_CART_FAILURE = 'REQUEST_ADD_TO_CART_FAILURE';
export const REQUEST_ADD_TO_CART_SUCCESS = 'REQUEST_ADD_TO_CART_SUCCESS';

export const REQUEST_REMOVE_FROM_CART = 'REQUEST_REMOVE_FROM_CART';
export const REQUEST_REMOVE_FROM_CART_FAILURE =
  'REQUEST_REMOVE_FROM_CART_FAILURE';
export const REQUEST_REMOVE_FROM_CART_SUCCESS =
  'REQUEST_REMOVE_FROM_CART_SUCCESS';

export const REQUEST_UPDATE_ITEM_QUANTITY = 'REQUEST_UPDATE_ITEM_QUANTITY';
export const REQUEST_UPDATE_ITEM_QUANTITY_FAILURE =
  'REQUEST_UPDATE_ITEM_QUANTITY_FAILURE';
export const REQUEST_UPDATE_ITEM_QUANTITY_SUCCESS =
  'REQUEST_UPDATE_ITEM_QUANTITY_SUCCESS';

export const REQUEST_CLEAR_CART_AND_ORDER = 'REQUEST_CLEAR_CART_AND_ORDER';
export const REQUEST_CLEAR_CART_AND_ORDER_SUCCESS =
  'REQUEST_CLEAR_CART_AND_ORDER_SUCCESS';

// MONIMALZ API
export const REQUEST_PRODUCT = 'REQUEST_PRODUCT';
export const REQUEST_PRODUCT_SUCCESS = 'REQUEST_PRODUCT_SUCCESS';
export const REQUEST_PRODUCT_FAILURE = 'REQUEST_PRODUCT_FAILURE';

export const REQUEST_RESET_PASSWORD = 'REQUEST_RESET_PASSWORD';
export const REQUEST_RESET_PASSWORD_FAILURE = 'REQUEST_RESET_PASSWORD_FAILURE';
export const REQUEST_RESET_PASSWORD_SUCCESS = 'REQUEST_RESET_PASSWORD_SUCCESS';
export const REQUEST_RESET_PASSWORD_RESET = 'REQUEST_RESET_PASSWORD_RESET';

// ACCOUNT
export const ACCOUNT_REQUEST_LOGIN = 'ACCOUNT_REQUEST_LOGIN';
export const ACCOUNT_REQUEST_FAILURE = 'ACCOUNT_REQUEST_FAILURE';
export const ACCOUNT_REQUEST_SUCCESS = 'ACCOUNT_REQUEST_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const RESET_EMAIL_VALIDATION = 'RESET_EMAIL_VALIDATION';

// PROFILE
export const PROFILE_UPDATE = 'PROFILE_UPDATE';

export const REQUEST_CREATE_ACCOUNT_SUCCEEDED =
  'REQUEST_CREATE_ACCOUNT_SUCCEEDED';
export const REQUEST_CREATE_ACCOUNT_FAILURE = 'REQUEST_CREATE_ACCOUNT_FAILURE';

export const REQUEST_TOKEN = 'REQUEST_TOKEN';

import {
  ACCOUNT_REQUEST_LOGIN,
  PROFILE_UPDATE,
  LOGOUT,
  ACCOUNT_FORMS_ERRORS,
  REGISTER_FORMS_ERRORS,
  REQUEST_CREATE_ACCOUNT_SUCCEEDED,
  RESET_EMAIL_VALIDATION,
} from '../actions/actionTypes';

const initialState = {
  authenticated: false,
  registerErrors: {
    password: [],
    email: [],
  },
  email: '',
  locales: '',
  uuid: '',
  access_token: '',
  profile: {},
  profileErrors: {},
  err: '',
  errors: true,
  registered: false,
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACCOUNT_REQUEST_LOGIN:
      const { validity_ends, type, ...rest } = action.payload;
      return { ...state, ...rest };
    case PROFILE_UPDATE:
      return { ...state, profile: action.payload };
    case REGISTER_FORMS_ERRORS:
      return {
        ...state,
        registerErrors: {
          email: action.payload.emailErrors.email,
          password: action.payload.passwordErrors.password,
          cgu: action.payload.cguErrors.cgu,
        },
      };
    case ACCOUNT_FORMS_ERRORS:
      return {
        ...state,
        ...action.payload,
      };
    case REQUEST_CREATE_ACCOUNT_SUCCEEDED:
      return {
        ...state,
        ...action.payload,
        registered: true,
      };
    case RESET_EMAIL_VALIDATION:
      return {
        ...state,
        registered: false,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default accountReducer;

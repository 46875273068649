import {
  OPEN_MENU,
  CLOSE_MENU,
  OPEN_BASKET,
  CLOSE_BASKET,
  SELECT_MONIMALZ,
  START_SCROLLING,
  STOP_SCROLLING,
  RESET_NAV_STATE,
  UPDATE_CHECKOUT_TRANSACTION_STATUS,
  SHOW_ERROR,
  HIDE_ERROR,
  SHOW_TOAST,
  HIDE_TOAST,
  TOKEN_FAILED,
  TOKEN_OK,
  TOKEN_RESET,
  WELCOME_ANIMATION_END,
  LOGOUT,
  START_LOADING,
  STOP_LOADING,
  REQUEST_ADD_TO_CART_SUCCESS,
  REQUEST_CREATE_ACCOUNT_SUCCEEDED,
  REQUEST_RESET_PASSWORD_SUCCESS,
  REQUEST_RESET_PASSWORD_RESET,
  REQUEST_RESET_PASSWORD,
  ACCOUNT_REQUEST_LOGIN,
  RESET_CHECKOUT_TRANSACTION_STATUS,
  UPDATE_TRANSACTION_STATUS,
  RESET_TRANSACTION_STATUS,
  SET_FROM_WEBSITE,
} from '../actions/actionTypes';

const initialState = {
  menuIsOpen: false,
  basketIsOpen: false,
  selectedMonimalz: null,
  isScrolling: false,
  isMobile: false,
  isLoading: false,
  isErrorDisplayed: false,
  errorMessage: '',
  isToastDisplayed: false,
  toastMessage: '',
  welcomeAnimationPlayed: false,
  resetPassword: false,
  isTokenValid: null,
  fromWebsite: false,
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_MONIMALZ:
      return { ...state, selectedMonimalz: action.payload };
    case REQUEST_ADD_TO_CART_SUCCESS:
    case OPEN_BASKET:
      return { ...state, basketIsOpen: true };
    case CLOSE_BASKET:
      return { ...state, basketIsOpen: false };
    case OPEN_MENU:
      return { ...state, menuIsOpen: true };
    case CLOSE_MENU:
      return { ...state, menuIsOpen: false };
    case START_SCROLLING:
      return { ...state, isScrolling: true };
    case STOP_SCROLLING:
      return { ...state, isScrolling: false };
    case RESET_NAV_STATE:
      return {
        ...initialState,
        ...action.payload,
        welcomeAnimationPlayed: state.welcomeAnimationPlayed,
        isToastDisplayed: state.isToastDisplayed,
        toastMessage: state.toastMessage,
      };
    case REQUEST_RESET_PASSWORD:
    case RESET_CHECKOUT_TRANSACTION_STATUS:
    case RESET_TRANSACTION_STATUS:
    case START_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case REQUEST_CREATE_ACCOUNT_SUCCEEDED:
    case ACCOUNT_REQUEST_LOGIN:
    case UPDATE_TRANSACTION_STATUS:
    case UPDATE_CHECKOUT_TRANSACTION_STATUS:
    case STOP_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case TOKEN_FAILED:
      return {
        ...state,
        isTokenValid: false,
      };
    case TOKEN_OK:
      return {
        ...state,
        isTokenValid: true,
      };
    case TOKEN_RESET:
      return {
        ...state,
        isTokenValid: null,
      };
    case SHOW_ERROR:
      return {
        ...state,
        isLoading: false,
        isErrorDisplayed: true,
        errorMessage: action.payload,
      };
    case HIDE_ERROR:
      return {
        ...state,
        isErrorDisplayed: false,
      };
    case SHOW_TOAST:
      return {
        ...state,
        isLoading: false,
        isToastDisplayed: true,
        toastMessage: action.payload,
      };
    case HIDE_TOAST:
      return {
        ...state,
        isToastDisplayed: false,
      };
    case WELCOME_ANIMATION_END:
      return {
        ...state,
        welcomeAnimationPlayed: true,
      };
    case LOGOUT:
      return initialState;
    case REQUEST_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        resetPassword: true,
      };
    case REQUEST_RESET_PASSWORD_RESET:
      return {
        ...state,
        isLoading: false,
        resetPassword: false,
      };
    case SET_FROM_WEBSITE:
      return {
        ...state,
        fromWebsite: action.payload.fromWebsite,
      };
    default:
      return state;
  }
};

export default appReducer;

import { MONTH_WORDINGS } from '../constants/date';

/**
 * @param {string} search
 * search is a key of location object
 * @returns {object} || {null}
 * returns null if no search
 */
export const getUrlParams = search => {
  if (!search || search.charAt(0) !== '?' || !~search.indexOf('=')) {
    return false;
  }
  return search
    .substring(1)
    .split('&')
    .reduce((acc, curr) => {
      const [key, value] = curr.split('=');
      acc[key] = value;
      return acc;
    }, {});
};

/**
 * @param {number} amount
 * the amount to format
 * @param {string} currency
 * the currency to format in
 * @returns {string}
 * returns price formatted e.g.: €240.00
 */
export const formatPrices = (amount, currency = 'EUR') => {
  const currencySign = currency => {
    switch (currency) {
      case 'EUR':
        return '€';
      case 'USD':
        return '$';
      default:
        return '€';
    }
  };
  const amountStr = amount.toString();
  const pos = amountStr.length - 2;
  const logo = currencySign(currency);
  return pos > 0
    ? [logo, amountStr.slice(0, pos), '.', amountStr.slice(pos)].join('')
    : `${logo}0.00`;
};

/**
 *
 * @param {string} millSec
 * @param {boolean} noSunday
 * If the return day must not be Sunday
 * @returns {object} formatted date for wordings
 */
export const formatDate = (millSec, noSunday = false) => {
  let baseDate = millSec;
  if (noSunday) {
    const ONE_DAY = 1 * 24 * 60 * 60 * 1000;
    baseDate += new Date(baseDate).getDay() === 0 ? ONE_DAY : 0;
  }
  const year = new Date(baseDate).getFullYear();
  const month = new Date(baseDate).getMonth();
  const day = new Date(baseDate).getDate();

  return {
    day,
    month: MONTH_WORDINGS[month],
    year,
  };
};

import { getNbItemsInCart } from '../utils/monimalz';

import {
  REQUEST_PRODUCT_SUCCESS,
  REQUEST_PRODUCT_FAILURE,
  REQUEST_ADD_TO_CART_SUCCESS,
  REQUEST_REMOVE_FROM_CART_SUCCESS,
  REQUEST_UPDATE_ITEM_QUANTITY_SUCCESS,
  REQUEST_CLEAR_CART_AND_ORDER_SUCCESS,
} from '../actions/actionTypes';

const initialState = {
  products: {},
  categories: {},
  cart: {
    nbItems: 0,
    items: [],
    displayPrice: {
      with_tax: {},
      without_tax: {},
    },
  },
  order: {},
  monimalzs: [],
  accessories: [],
  orderSuccessful: false,
  errors: [],
};

const shopReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ADD_TO_CART_SUCCESS:
    case REQUEST_REMOVE_FROM_CART_SUCCESS:
    case REQUEST_UPDATE_ITEM_QUANTITY_SUCCESS:
      return {
        ...state,
        cart: {
          items: action.payload.items,
          displayPrice: action.payload.displayPrice,
          nbItems: getNbItemsInCart(action.payload.items),
        },
      };
    case REQUEST_CLEAR_CART_AND_ORDER_SUCCESS:
      return {
        ...state,
        cart: initialState.cart,
        order: initialState.order,
        orderSuccessful: initialState.orderSuccessful,
      };
    case REQUEST_PRODUCT_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case REQUEST_PRODUCT_FAILURE:
      return {
        ...state,
        products: {},
      };
    default:
      return state;
  }
};

export default shopReducer;

/* eslint-disable camelcase */

import omit from 'lodash/omit';
import cloneDeep from 'lodash/cloneDeep';
import { TVA } from '../constants/checkout';
import { formatPrices } from './stringOperations';

import Mona from '../assets/img/products/Mona.png';
import Miko from '../assets/img/products/Miko.png';
import Mouki from '../assets/img/products/Mouki.png';

const images = {
  'miko-le-panda': Miko,
  'mona-la-baleine': Mona,
  'mouki-le-singe': Mouki,
};

const getNewPrice = items => {
  let displayPrice = 0;
  items.forEach(i => (displayPrice += i.value.amount));
  const formatted = formatPrices(displayPrice);
  const total_tva = formatPrices(displayPrice * TVA);
  return {
    with_tax: {
      amount: displayPrice,
      currency: 'EUR',
      formatted,
      total_tva,
    },
    without_tax: {
      amount: displayPrice,
      currency: 'EUR',
      formatted,
    },
  };
};

// TODO : documentation
export const serilalizeCart = cart =>
  cart.map(({ product_id, quantity }) => ({ product: product_id, quantity }));

export const getNbItemsInCart = cartItems =>
  cartItems.reduce((acc, { quantity }) => acc + quantity, 0);

export const formatProduct = product => {
  product.images = [];
  product.formatted = formatPrices(product.price, product.currency);
  product.files.forEach(({ type, url }) => {
    if (type === 'more') {
      product.images.push(url);
    } else if (type === 'main') {
      product.mainImage = images[product.slug];
    } else {
      product[type] = url;
    }
  });
  return omit(product, ['files']);
};

const addNewItem = (productId, product, quantity = 1) => ({
  name: product.name,
  image: {
    href: product.mainImage,
  },
  links: {
    product: `${process.env.GATSBY_MONIMALZ_API_URL}shop/items/${productId}`,
  },
  description: product.description,
  product_id: productId,
  quantity,
  unit_price: {
    amount: product.price,
    currency: product.currency,
    includes_tax: true,
  },
  value: {
    amount: product.price * quantity,
    currency: product.currency,
    includes_tax: true,
    formatted: formatPrices(product.price * quantity, product.currency),
  },
});

export const addProductToCart = (productId, quantity = 1, moltinState) => {
  const {
    cart: { items },
    products,
  } = moltinState;
  const cloneItems = cloneDeep(items);
  const product = products[productId];
  const productInCart = items.find(i => i.product_id === productId);
  if (productInCart) {
    const productIndex = items.map(e => e.product_id).indexOf(productId);
    const currItem = cloneItems[productIndex];
    currItem.quantity += quantity;
    const amount = currItem.quantity * product.price;
    currItem.value = {
      amount,
      currency: product.currency,
      includes_tax: true,
      formatted: formatPrices(amount, product.currency),
    };
  } else {
    cloneItems.push(addNewItem(productId, product, quantity));
  }
  return {
    items: cloneItems,
    displayPrice: getNewPrice(cloneItems),
  };
};

export const removeProductFromCart = (productId, moltinState) => {
  const {
    cart: { items },
  } = moltinState;
  const cloneItems = cloneDeep(items);
  const productIndex = items.map(e => e.product_id).indexOf(productId);
  const productInCart = items.find(i => i.product_id === productId);
  if (!productInCart || productIndex === -1) {
    return null;
  } else {
    cloneItems.splice(productIndex, 1);
  }
  return {
    items: cloneItems,
    displayPrice: getNewPrice(cloneItems),
  };
};

export const updateItemQuantity = (productId, newQuantity, moltinState) => {
  const {
    cart: { items },
  } = moltinState;
  const cloneItems = cloneDeep(items);
  const productInCart = items.find(i => i.product_id === productId);
  const productIndex = items.map(e => e.product_id).indexOf(productId);
  if (!productInCart || productIndex === -1) {
    return null;
  } else {
    const currItem = cloneItems[productIndex];
    currItem.quantity = newQuantity;
    const amount = currItem.quantity * currItem.unit_price.amount;
    currItem.value = {
      ...currItem.value,
      amount,
      formatted: formatPrices(amount, currItem.unit_price.currency),
    };
  }
  return {
    items: cloneItems,
    displayPrice: getNewPrice(cloneItems),
  };
};

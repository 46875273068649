export const MONTH_WORDINGS = {
  0: 'month_january',
  1: 'month_february',
  2: 'month_march',
  3: 'month_april',
  4: 'month_may',
  5: 'month_june',
  6: 'month_july',
  7: 'month_august',
  8: 'month_september',
  9: 'month_october',
  10: 'month_november',
  11: 'month_december',
};

export const DAY_WORDINGS = {
  0: 'day_sunday',
  1: 'day_monday',
  2: 'day_tuesday',
  3: 'day_wednesday',
  4: 'day_thursday',
  5: 'day_friday',
  6: 'day_saturday',
};

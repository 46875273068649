import React from 'react';

import { createStore, applyMiddleware, redux, compose } from 'redux';
import { Provider } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import createEncryptor from 'redux-persist-transform-encrypt';
import { PersistGate } from 'redux-persist/integration/react';

import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from './src/reducers';

const encryptor = createEncryptor({
  secretKey: process.env.GATSBY_ENCRYPT_PERSIST,
  onError: function(error) {
    console.log('Redux persist encryptor error => ', { error });
  },
});

const rootPersistConfig = {
  key: 'Mnz',
  storage,
  transforms: [encryptor],
};
const MNZ_ENV = process.env.MNZ_ENV || process.env.NODE_ENV || 'development';
const persistedReducer = persistReducer(rootPersistConfig, rootReducer);
const configMiddlewares = [thunk];

if (MNZ_ENV === 'development') {
  configMiddlewares.push(
    createLogger({
      collapsed: true,
    })
  );
}

const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
  MNZ_ENV === 'development'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(...configMiddlewares))
);
const persistor = persistStore(store);

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      {element}
    </PersistGate>
  </Provider>
);

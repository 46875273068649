// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-legal-page-template-js": () => import("/home/mhammerc/monimalz-web-gatsby/src/templates/legalPageTemplate.js" /* webpackChunkName: "component---src-templates-legal-page-template-js" */),
  "component---src-pages-3-ds-loader-js": () => import("/home/mhammerc/monimalz-web-gatsby/src/pages/3DS_loader.js" /* webpackChunkName: "component---src-pages-3-ds-loader-js" */),
  "component---src-pages-404-js": () => import("/home/mhammerc/monimalz-web-gatsby/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/home/mhammerc/monimalz-web-gatsby/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-account-orders-delivery-js": () => import("/home/mhammerc/monimalz-web-gatsby/src/pages/account/orders/delivery.js" /* webpackChunkName: "component---src-pages-account-orders-delivery-js" */),
  "component---src-pages-account-orders-details-js": () => import("/home/mhammerc/monimalz-web-gatsby/src/pages/account/orders/details.js" /* webpackChunkName: "component---src-pages-account-orders-details-js" */),
  "component---src-pages-account-orders-index-js": () => import("/home/mhammerc/monimalz-web-gatsby/src/pages/account/orders/index.js" /* webpackChunkName: "component---src-pages-account-orders-index-js" */),
  "component---src-pages-account-profile-index-js": () => import("/home/mhammerc/monimalz-web-gatsby/src/pages/account/profile/index.js" /* webpackChunkName: "component---src-pages-account-profile-index-js" */),
  "component---src-pages-checkout-basket-js": () => import("/home/mhammerc/monimalz-web-gatsby/src/pages/checkoutBasket.js" /* webpackChunkName: "component---src-pages-checkout-basket-js" */),
  "component---src-pages-checkout-shipping-js": () => import("/home/mhammerc/monimalz-web-gatsby/src/pages/checkoutShipping.js" /* webpackChunkName: "component---src-pages-checkout-shipping-js" */),
  "component---src-pages-checkout-success-js": () => import("/home/mhammerc/monimalz-web-gatsby/src/pages/checkoutSuccess.js" /* webpackChunkName: "component---src-pages-checkout-success-js" */),
  "component---src-pages-contact-js": () => import("/home/mhammerc/monimalz-web-gatsby/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("/home/mhammerc/monimalz-web-gatsby/src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("/home/mhammerc/monimalz-web-gatsby/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("/home/mhammerc/monimalz-web-gatsby/src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-product-js": () => import("/home/mhammerc/monimalz-web-gatsby/src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-register-js": () => import("/home/mhammerc/monimalz-web-gatsby/src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-reset-password-success-js": () => import("/home/mhammerc/monimalz-web-gatsby/src/pages/reset_password_success.js" /* webpackChunkName: "component---src-pages-reset-password-success-js" */),
  "component---src-pages-reset-password-js": () => import("/home/mhammerc/monimalz-web-gatsby/src/pages/reset_password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-success-js": () => import("/home/mhammerc/monimalz-web-gatsby/src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */)
}

exports.data = () => import("/home/mhammerc/monimalz-web-gatsby/.cache/data.json")


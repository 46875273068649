import { combineReducers } from 'redux';

import app from './appReducer';
import checkoutForm from './checkoutReducer';
import account from './accountReducer';
import shop from './shopReducer';

export default combineReducers({
  app,
  shop,
  checkoutForm,
  account,
});
